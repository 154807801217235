<template>
  <div class="common-container  home-container">
    <div class="common-content flex home-wrapper">
      <div class="flex home-left-box">
        <div class="user-avatar">
          <img :src="useAvatar" alt="" />
          <div class="user-type-box" v-if="isAvatarName">
            {{ isAvatarName }}
          </div>
        </div>
        <div class="title-1 flex user-name">
          {{
            $getUserName(userInfo ? userInfo.username || userInfo.nickName : "")
          }}
          <img v-if="vipPic && isVip" :src="vipPic" alt="" class="vip-img" />
        </div>
        <div class="user-info" v-if="isVip">
          <div class="user-kj" v-if="getPackageSimpleByVal('feaUserSpace')">
            云空间：{{ (packData && packData.spaceUsed).toFixed(2) || 0 }}M /
            {{
              (packData &&
                packData.packageSimple &&
                packData.packageSimple.feaUserSpace) ||
                0
            }}M
          </div>
          <div class="user-date">
            到期日期：{{ $formatDate(packData.endTime, "yyyy-MM-dd") }}
          </div>
        </div>
      </div>
      <div class="home-right-box">
        <div class="flex work-top">
          <div class="work-top-left">
            <div class="work-top-title">
              工作台
            </div>
            <div class="work-top-counte">工程总数：{{ total }}个</div>
          </div>
          <div class="work-top-right">
            <el-input
              placeholder="请输入工程名称进行搜索"
              v-model="keyWord"
              @keyup.enter.native="onSearch"
              @clear="clearKey"
              clearable
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
        <div class="work-wrapper">
          <div class="scroll-Y work-main flex">
            <div class="work-item flex-center " @click="addCad" v-if="showAdd">
              <div class="flex work-add">
                <i class="el-icon-plus"></i>
                <div class="addNew">
                  创建新工程
                </div>
              </div>
            </div>
            <div
              class="flex-center work-item"
              v-for="(it, index) in tabData"
              :key="index"
              @click="goDetail(it)"
            >
              <div class="work-img-box">
                <img :src="it.snapshot ? it.snapshot : snapshot" alt="" />
              </div>
              <div class="flex work-info-box">
                <div class="work-info">
                  <div class="text_hidden work-name">{{ it.name }}</div>
                  <div class="work-type">
                    {{ getProjectType(it.projectType) }}
                  </div>
                </div>
                <div class="work-desc">
                  文件大小：{{ getFileRl(it.file) || "0.00" }} M
                </div>
                <div class="work-desc">
                  最近编辑时间：
                  {{ $formatDate(it.updateTime, "yyyy-MM-dd hh:mm:ss") }}
                </div>
              </div>
              <div class="flex-center work-mask-box">
                <div
                  class="work-mask-item look"
                  v-if="getPackageSimpleByVal('feaOnlineView')"
                  @click.stop="goDetail(it, index)"
                ></div>
                <div
                  class="work-mask-item edit"
                  @click.stop="editCad(it, index)"
                ></div>
                <div
                  class="work-mask-item down"
                  v-if="
                    it.file &&
                      it.file.id &&
                      getPackageSimpleByVal('feaDwgDownload')
                  "
                  @click.stop="onShowFile(it)"
                ></div>
                <div
                  class="work-mask-item del"
                  @click.stop="delCad(it.id)"
                ></div>
              </div>
            </div>
          </div>
          <div class="work-page-box">
            <el-pagination
              :total="total"
              :current-page="page + 1"
              :page-sizes="pageSizes"
              layout="total, prev, pager, next, sizes"
              @size-change="sizeChange"
              @current-change="pageChange"
            />
          </div>
        </div>
      </div>

      <el-dialog
        :append-to-body="true"
        :close-on-click-modal="false"
        :visible.sync="createDialog"
        :modal="true"
        :modal-append-to-body="false"
        :title="!addDialog ? '编辑工程' : '新建工程'"
        width="600px"
        style="text-align:left"
        v-dialogDrag
      >
        <div
          class="dialog-edit"
          
        >
          <div
            class="flex-center"
            style="color:#5790f9;"
            @click="onShowIdentify"
          >
            <svg
              t="1684248926130"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1678"
              width="20"
              height="20"
            >
              <path
                d="M247.0912 996.1472 100.5568 996.1472c-39.936 0-72.4992-32.5632-72.4992-72.4992L28.0576 81.92c0-39.936 32.5632-72.4992 72.4992-72.4992l766.0544 0c39.936 0 72.4992 32.5632 72.4992 72.4992l0 210.5344c0 12.3904-10.0352 22.528-22.528 22.528s-22.528-10.0352-22.528-22.528L894.0544 81.92c0-15.1552-12.288-27.5456-27.5456-27.5456L100.5568 54.3744c-15.1552 0-27.5456 12.288-27.5456 27.5456L73.0112 923.648c0 15.1552 12.288 27.5456 27.5456 27.5456l146.5344 0c12.3904 0 22.528 10.0352 22.528 22.528S259.4816 996.1472 247.0912 996.1472z"
                fill="#5790f9"
                p-id="1679"
              ></path>
              <path
                d="M745.2672 192.1024 174.6944 192.1024c-12.3904 0-22.528-10.0352-22.528-22.528s10.0352-22.528 22.528-22.528l570.5728 0c12.3904 0 22.528 10.0352 22.528 22.528S757.6576 192.1024 745.2672 192.1024z"
                fill="#5790f9"
                p-id="1680"
              ></path>
              <path
                d="M437.6576 429.6704 174.6944 429.6704c-12.3904 0-22.528-10.0352-22.528-22.528s10.0352-22.528 22.528-22.528l262.9632 0c12.3904 0 22.528 10.0352 22.528 22.528S450.1504 429.6704 437.6576 429.6704z"
                fill="#5790f9"
                p-id="1681"
              ></path>
              <path
                d="M620.6464 310.8864 174.6944 310.8864c-12.3904 0-22.528-10.0352-22.528-22.528s10.0352-22.528 22.528-22.528l445.952 0c12.3904 0 22.528 10.0352 22.528 22.528S633.1392 310.8864 620.6464 310.8864z"
                fill="#5790f9"
                p-id="1682"
              ></path>
              <path
                d="M399.6672 1009.8688c-6.2464 0-12.288-2.56-16.5888-7.2704-5.2224-5.7344-7.168-13.7216-5.12-21.2992l40.8576-146.6368c1.024-3.6864 3.072-7.168 5.7344-9.8304l408.9856-408.9856c14.1312-14.0288 36.9664-14.0288 51.0976 0l97.792 97.792c6.8608 6.8608 10.5472 15.872 10.5472 25.4976s-3.7888 18.7392-10.5472 25.4976L928.8704 618.496c-4.1984 4.1984-9.9328 6.5536-15.872 6.5536s-11.6736-2.3552-15.872-6.5536l-66.048-66.048c-8.8064-8.8064-8.8064-23.04 0-31.8464s23.04-8.8064 31.8464 0l50.176 50.176 31.4368-31.4368L859.136 454.0416 460.6976 852.48 431.104 958.6688 546.7136 936.96l231.7312-231.7312c5.0176-5.4272 50.7904-52.6336 107.2128-56.7296 12.3904-0.9216 23.1424 8.3968 24.064 20.7872 0.9216 12.3904-8.3968 23.1424-20.7872 24.064-40.3456 2.9696-77.4144 42.2912-77.824 42.7008-0.2048 0.2048-0.4096 0.512-0.7168 0.7168L573.5424 973.7216c-3.1744 3.1744-7.2704 5.3248-11.776 6.2464l-158.0032 29.5936C402.432 1009.7664 401.1008 1009.8688 399.6672 1009.8688z"
                fill="#5790f9"
                p-id="1683"
              ></path>
            </svg>
            智能填写
          </div>
          <div class="animated bounceIn dialog-edit-box">
            <div class="edit-title">
              参考实例
            </div>
            <div class="edit-item">工程名称：{{ intelligenceData.name }}</div>
            <div class="edit-item">
              工程类型：{{ intelligenceData.projectType }}
            </div>
            <div class="edit-item">
              工程区域：{{ intelligenceData.buildAddress }}
            </div>
            <div class="edit-item">
              建筑面积：{{ intelligenceData.buildingArea }}m2
            </div>
            <div class="edit-item">
              建筑高度：{{ intelligenceData.buildingHeight }}m
            </div>
            <div class="edit-item">
              使用人数：{{ intelligenceData.usePeople }}人
            </div>
          </div>
        </div>
        <div class="form-wrapper" v-closeSelect>
          <el-form
            ref="form"
            :model="dialogData"
            :rules="createRules"
            size="small"
            label-width="auto"
          >
            <el-form-item label="工程名称" prop="name">
              <el-input v-model="dialogData.name" class="form-input" />
            </el-form-item>
            <el-form-item
              prop="projectTypeId"
              label="工程类型"
              class="form-row"
            >
              <el-select
                v-model="dialogData.projectType"
                style="margin-right: 10px;margin-bottom: 0px;width:100%;"
                filterable
                placeholder="请选择"
                ref="refType"
                @change="choseType"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="工程区域" class="form-row" prop="provinceCode">
              <div class="addres-wrapper">
                <el-select
                  v-model="dialogData.provinceCode"
                  placeholder="请选择"
                  @change="changeProvice"
                  ref="refcity1"
                >
                  <el-option
                    v-for="item in proviceList"
                    :key="item.areaCode"
                    :label="item.areaName"
                    :value="item.areaCode"
                    value-key="areaCode"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="dialogData.cityCode"
                  placeholder="请选择"
                  @change="changeCity"
                  ref="refCity2"
                >
                  <el-option
                    v-for="item in cityList"
                    :key="item.areaCode"
                    :label="item.areaName"
                    :value="item.areaCode"
                    value-key="areaCode"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="dialogData.countyCode"
                  placeholder="请选择"
                  ref="refCity3"
                  @change="changeCountry"
                >
                  <el-option
                    v-for="item in countyList"
                    :key="item.areaCode"
                    :label="item.areaName"
                    :value="item.areaCode"
                    value-key="areaCode"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item label="创建类型" prop="type">
              <el-radio-group v-model="dialogData.type" :disabled="!addDialog">
                <el-radio :label="1">新建</el-radio>
                <el-radio :label="2">导入</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item
              v-if="dialogData.type == 2"
              label="工程文件"
              prop="fileraw"
              style="position: relative;"
            >
              <el-upload
                ref="uploadmap"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="handleMapChange"
                :http-request="uploadFileMap"
                class="avatar-uploader"
                action=""
                accept=".dwg"
                :disabled="!addDialog"
                drag
              >
                <img :src="getWorkImg(dialogData.snapshot)" class="avatar" />
              </el-upload>
              <div
                class="file-name"
                v-if="dialogData.file && dialogData.file.fileName"
              >
                {{ dialogData.file.fileName }}
              </div>
              <div v-if="!isUploadFile" class="file-name file-tips">
                未导入工程文件
              </div>
            </el-form-item>
            <template>
              <el-form-item
                label="建筑面积"
                prop="buildingArea"
                v-if="getPackageSimpleByVal('feaCreateProjectArea')"
              >
                <el-input
                  v-model="dialogData.buildingArea"
                  class="form-input"
                  type="number"
                  @input="
                    dialogData.buildingArea = dialogData.buildingArea
                      .replace(/[^\d.]/g, '')
                      .replace(/^\D*(\d{1,12}(?:\.\d{0,2})?).*$/g, '$1')
                  "
                >
                  <span slot="suffix">(平方米)</span>
                </el-input>
              </el-form-item>
              <el-form-item label="建筑高度" prop="buildingHeight">
                <el-input
                  v-model="dialogData.buildingHeight"
                  class="form-input"
                  type="number"
                  @input="
                    dialogData.buildingHeight = dialogData.buildingHeight
                      .replace(/[^\d.]/g, '')
                      .replace(/^\D*(\d{1,12}(?:\.\d{0,2})?).*$/g, '$1')
                  "
                >
                  <span slot="suffix">(米)</span>
                </el-input>
              </el-form-item>
              <el-form-item label="使用人数">
                <el-input
                  v-model.number="dialogData.userCount"
                  class="form-input"
                >
                  <span slot="suffix">(人)</span>
                </el-input>
              </el-form-item>
            </template>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button
            @click="cancelCreateDialog"
            size="nomal"
            native-type="button"
            >取消</el-button
          >
          <el-button
            @click="doSubmit"
            type="primary"
            size="nomal"
            :loading="loadingBtn"
            >确定</el-button
          >
        </div>
        <el-dialog
          width="30%"
          title="智能提示"
          :visible.sync="showIdentify"
          append-to-body
        >
          <el-input
            v-model="identifyVal"
            show-word-limit
            type="textarea"
            resize="none"
            :rows="sizeVal"
          />
          <div slot="footer" class="dialog-footer">
            <el-button
              @click="showIdentify = false"
              size="nomal"
              native-type="button"
              >取消</el-button
            >
            <el-button
              @click.stop="saveIdentify"
              type="primary"
              size="nomal"
              native-type="button"
              :loading="loadIdentify"
              >确定</el-button
            >
          </div>
        </el-dialog>
      </el-dialog>
      <el-dialog
        :append-to-body="true"
        :close-on-click-modal="false"
        :visible.sync="showDown"
        :modal="true"
        :modal-append-to-body="false"
        title="选择下载类型"
        width="450px"
        v-dialogDrag
      >
        <div class="flex-center form-wrapper" style="flex-direction:column;">
          <el-radio-group v-model="downType" @change="changeDownType">
            <el-radio label="dwg">dwg</el-radio>
            <el-radio label="dxf">dxf</el-radio>
            <!-- <el-radio label="pdf">pdf</el-radio> -->
          </el-radio-group>

          <div
            v-if="downType !== 'pdf'"
            style="margin-top:20px;
         display:flex;align-items:center;"
          >
            <div style="margin-right:16px;">选择版本</div>
            <el-select
              v-model="selectedValueIndex"
              placeholder="请选择版本"
              ref="refBb"
            >
              <el-option
                v-for="(item, index) in downValList"
                :key="item.name"
                :label="item.name"
                :value="index"
                value-key="value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelDown" size="nomal" native-type="button"
            >取消</el-button
          >
          <el-button @click="onDownFile" type="primary" size="nomal"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { get, post, del } from "@/api/index";
import { mapGetters } from "vuex";
import { onCarProject, postCarProject, editCarProject } from "@/api/cadHome";
import { addressMixin } from "@/utils/addressMixin";
import { initDataMOD } from "@/mixins/initDataMOD";
import axios from "axios";
import { getToken } from "@/utils/auth";
import { dialogMixin } from "@/utils/dialogMixin";
export default {
  name: "home",
  mixins: [dialogMixin, addressMixin, initDataMOD],
  components: {},
  data() {
    return {
      // 搜索关键词
      keyWord: "",
      list: [],
      // cad新增编辑数据
      showDialog: false,
      addDialog: true,
      loadingBtn: false,
      dialogIndex: -1,
      dialogData: this.resetFormData(),
      typeList: [],
      pageSizes: [15, 55, 105, 155],
      createRules: {
        name: [{ required: true, message: "请输入工程名称", trigger: "blur" }],
        projectTypeId: [
          { required: true, message: "请选择工程类型", trigger: "change" }
        ],
        provinceCode: [
          { required: true, trigger: "change", message: "请选择工程区域" }
        ],
        type: [
          { required: true, message: "请选择创建类型", trigger: "change" }
        ],
        fileraw: [
          { required: true, message: "未导入工程文件", trigger: "change" }
        ],
        buildingArea: [
          { required: true, message: "请输入建筑面积", trigger: "blur" }
        ],
        buildingHeight: [
          { required: true, message: "请输入建筑高度", trigger: "blur" }
        ]
      },
      total: 0,
      page: 0,
      size: 15,
      snapshot: require("images/home/work.png"),
      vipImg: require("images/home/vip.png"),
      svipImg: require("images/home/svip.png"),
      // 创建工程
      createDialog: false,
      imgraw: null,
      isUploadFile: false,
      fileraw: null,
      type: "dialogData",
      avatar: require("images/avatar.png"),
      url: this.$setApi("/cad/project/page"),
      showDown: false,
      downType: "dwg",
      selectedValueIndex: 0,
      downValList: [
        {
          name: "DEFAULT",
          value: "DEFAULT"
        },
        {
          name: "CAD2000",
          value: "V2000"
        },
        {
          name: "CAD2004",
          value: "V2004"
        },
        {
          name: "CAD2007",
          value: "V2007"
        },
        {
          name: "CAD2010",
          value: "V2010"
        },
        {
          name: "CAD2013",
          value: "V2013"
        },
        {
          name: "CAD2018",
          value: "V2018"
        }
      ],
      downData: null,
      // 智能识别
      sizeVal: 10,
      showIdentify: false,
      loadIdentify: false,
      identifyVal: "",
      identifyData: window.identifyData,
      intelligenceData: window.intelligenceData
    };
  },
  created() {
    this.getTypeData();
  },
  computed: {
    ...mapGetters(["userInfo", "packData"]),
    useAvatar() {
      return this.userInfo && this.userInfo.avatarUrl
        ? this.userInfo.avatarUrl
        : this.avatar;
    },
    isPtUser() {
      if (
        this.packData &&
        this.packData.packageSimple &&
        this.packData.packageSimple.packageType &&
        this.packData.packageSimple.packageType == "FREE"
      )
        return true;
      else return false;
    },
    isAvatarName() {
      if (
        this.packData &&
        this.packData.packageSimple &&
        this.packData.packageSimple.packageType &&
        this.packData.packageSimple.packageType == "TRIAL"
      ) {
        return "体验者";
      } else return "";
    },
    isVip() {
      let vip = false;
      if (
        this.packData &&
        this.packData.packageSimple &&
        this.packData.packageSimple.packageType
      ) {
        if (
          this.packData.packageSimple.packageType == "VIP" ||
          this.packData.packageSimple.packageType == "SVIP"
        )
          vip = true;
      }
      return vip;
    },
    vipPic() {
      let pic = "";
      if (
        this.packData &&
        this.packData.packageSimple &&
        this.packData.packageSimple.packageType
      ) {
        if (this.packData.packageSimple.packageType == "VIP") pic = this.vipImg;
        else if (this.packData.packageSimple.packageType == "SVIP")
          pic = this.svipImg;
      }
      return pic;
    },
    showAdd() {
      let isAdd = false;
      if (
        this.packData &&
        this.packData.packageSimple &&
        this.packData.packageSimple.feaCreateProjectCount &&
        this.packData.packageSimple.feaCreateProjectCount == -100
      )
        isAdd = true;
      else if (
        this.packData &&
        this.packData.packageSimple &&
        this.packData.packageSimple.feaCreateProjectCount &&
        this.packData.packageSimple.feaCreateProjectCount > 0 &&
        this.packData.packageSimple.feaCreateProjectCount > this.total
      )
        isAdd = true;
      return isAdd;
    },
    areaNum() {
      let num = 0;
      if (this.getPackageSimpleByVal("feaCreateProjectArea")) {
        num = Number(this.packData.packageSimple.feaCreateProjectArea);
      }
      return num;
    }
  },
  activated() {
    this.keyWord = "";
    this.init();
  },
  mounted() {},
  methods: {
    // 智能识别-start
    onShowIdentify() {
      this.identifyVal = "";
      //       this.identifyVal = `1.工程名称：江西医学高等专科学校改扩建工程设计--上饶医学健康科普中心项目
      //       2.建筑地点：江西省上饶市
      //         3.建设单位：江西医学高等专科学院,
      // 4.建筑总用地面积：校园总体平衡,
      // 5.建筑总面积：4979.28 m2（地上：4444.76 m2，其中架空连廊面积534.52 m2；
      // 6.建筑基底面积：3360.97平方米；容积率：校园总体平衡；建筑密度：校园总体平衡；建筑层数：3；地上最高：3层；地下：0层；建筑高度：17.85米（按照防火规范）。
      // 7.建筑结构形式：钢筋混凝土现浇框架结构
      // 8.抗震设防烈度：6度
      // 9.建筑类别：多层公共建筑
      // 10.建筑耐火等级：二级
      // 11.设计耐久年限：按50年设计
      // 12.屋面防水等级：Ⅰ级
      // 12.工程类型：普通工程 `;
      this.showIdentify = true;
    },
    saveIdentify() {
      this.identifyData.map(item => {
        if (this.identifyVal.indexOf(item.name) > -1) {
          let str = JSON.parse(JSON.stringify(this.identifyVal));
          let resulte = this.subSlotVal(str, item);
          if (
            Object.prototype.hasOwnProperty.call(this.dialogData, item.key) &&
            resulte
          )
            if (item.name !== "建筑地点" && item.name !== "工程类型")
              this.dialogData[item.key] = resulte;
            else if (item.name == "建筑地点") {
              this.setAddressVal(
                this.subBeforeVal(resulte, "省"),
                this.subTwoVal(resulte, "省", "市"),
                this.subTwoVal(resulte, "市", "区")
              );
            } else if (item.name == "工程类型") {
              if (this.typeList.length > 0 && resulte) {
                this.typeList.some(it => {
                  if (it.name.indexOf(resulte) > -1) {
                    this.dialogData.productId = it.name;
                    this.dialogData.projectType = it.id;
                    if (!this.imgraw) this.dialogData.snapshot = it.icon;
                    return true;
                  }
                });
                console.log(
                  "工程类型",
                  item.name,
                  resulte,
                  this.typeList,
                  this.dialogData.snapshot
                );
              }
            }
        }
      });
      this.showIdentify = false;
    },
    sbuNumVal(str = "js截取字符串123数字之前的字符") {
      let regex = /(\D*)\d+/; // 匹配数字之前的非数字字符
      return str.match(regex)[1]; // 提取捕获组中的结果
    },
    subSlotVal(str, item) {
      let startIndex = str.indexOf(item.name) + item.name.toString().length + 1; // 获取 "a" 的索引位置后一位
      let endIndex = str.indexOf(item.end, startIndex); // 从 startIndex 开始寻找逗号的索引位置
      return str.substring(startIndex, endIndex); // 使用 substring() 方法截取子字符串
    },
    subTwoVal(str, startName, endName) {
      let startIndex = str.indexOf(startName) + 1; // 获取 "a" 的索引位置后一位
      let endIndex = str.indexOf(endName, startIndex); // 从 startIndex 开始寻找逗号的索引位置
      return str.substring(startIndex, endIndex); // 使用 substring() 方法截取子字符串
    },
    subBeforeVal(str, keyword) {
      return str.substr(0, str.indexOf(keyword));
    },
    setAddressVal(provide, city, country) {
      if (this.proviceList.length > 0 && provide) {
        this.proviceList.some(item => {
          if (item.areaName.indexOf(provide) > -1) {
            this.dialogData.provide = item.areaName;
            this.dialogData.provinceCode = item.areaCode;
            return true;
          }
        });
        get(
          this.$setApi("/sys/area/cites/" + this.dialogData.provinceCode)
        ).then(res => {
          this.cityList = res.data;
          if (this.cityList.length > 0 && city) {
            this.cityList.some(item => {
              if (item.areaName.indexOf(city) > -1) {
                this.dialogData.city = item.areaName;
                this.dialogData.cityCode = item.areaCode;
                return true;
              }
            });
            get(
              this.$setApi("/sys/area/counties/" + this.dialogData.cityCode)
            ).then(res => {
              this.countyList = res.data;
              if (this.countyList.length > 0 && country) {
                this.countyList.some(item => {
                  if (item.areaName.indexOf(country) > -1) {
                    this.dialogData.country = item.areaName;
                    this.dialogData.countyCode = item.areaCode;
                    return true;
                  }
                });
              }
            });
          }
        });
      }
    },
    // 智能识别-end
    // 图片展示
    showImg(it) {
      let imageList = [];
      if (it.file && it.file.id) imageList.push(it.snapshot);
      else imageList.push(this.snapshot);
      console.log("images", imageList, this.$viewerApi);
      this.$viewerApi({
        images: imageList
      });
    },
    // 初始化请求参数
    beforeInit() {
      this.params = {
        pageNum: this.page,
        pageSize: this.size,
        param: {}
      };
      if (this.keyWord) this.params.param.name = this.keyWord;
      this.request = { url: this.url, method: "post" };
      return true;
    },
    // 搜索
    onSearch() {
      this.page = 0;
      this.total = 0;
      this.init();
    },
    clearKey() {
      this.keyWord = "";
      this.onSearch();
    },
    getShowImg() {},
    getWorkImg(snapshot) {
      return this.dialogData && this.dialogData.snapshot
        ? this.dialogData.snapshot
        : snapshot
        ? snapshot
        : this.snapshot;
    },
    getFileRl(file) {
      if (file && file.fileSize && file.fileSize > 0)
        return (Number(file.fileSize) / 1024 / 1024).toFixed(2);
      return "";
    },
    getTypeData() {
      // 工程类型
      post(this.$setApi("/sys/dict/data/page"), {
        pageNum: 1,
        pageSize: 100,
        param: {
          dictType: "engineering-type"
        }
      }).then(res => {
        this.typeList = [];
        if (res.data && res.data.records && res.data.records.length > 0) {
          this.typeList = res.data.records.map(item => {
            return {
              id: item.id,
              name: item.dictLabel,
              icon: item.icon
            };
          });
          console.log("typeList", this.typeList);
        }
      });
    },

    onShowFile(it) {
      this.downType = "dwg";
      this.downData = null;
      if (this.getPackageSimpleByVal("feaDwgPdfDownload")) {
        this.downData = it;
        this.showDown = true;
      } else {
        this.downLoadFile(it);
      }
    },
    cancelDown() {
      this.downData = null;
      this.downType = "dwg";
      this.showDown = false;
    },
    changeDownType() {
      this.selectedValueIndex = 0;
    },
    onDownFile() {
      this.downLoadFile(this.downData);
    },
    // 下载
    downLoadFile(it) {
      const _this = this;
      let axios_load;
      if (it && it.id) {
        let data = it.id;
        if (this.getPackageSimpleByVal("feaDwgPdfDownload")) {
          if (this.downType == "pdf") {
            data = {
              projectId: it.id,
              json: "",
              width: 2000,
              height: 2000
            };
            axios_load = axios({
              url:
                window.configData.VUE_APP_BASEURL +
                `/cad/project/download/${this.downType}/`,
              method: "post",
              data,
              headers: {
                Authorization: "Bearer " + getToken()
              },
              responseType: "blob" // 表明返回服务器返回的数据类型
            });
          } else {
            data =
              it.id +
              `?width=2000&height=2000&version=${this.selectedValueIndex}`;
            if (this.downType == "dxf") {
              data = data + "&isdxf=true";
            }
            axios_load = axios({
              url:
                window.configData.VUE_APP_BASEURL +
                `/cad/project/download/dwg/` +
                data, // 请求地址
              method: "get",
              headers: {
                Authorization: "Bearer " + getToken()
              },
              responseType: "blob" // 表明返回服务器返回的数据类型
            });
          }
        }
        axios_load
          .then(res => {
            console.log("res", res);
            if (res.data) {
              let url = window.URL.createObjectURL(res.data);
              let link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              // link.setAttribute("download", it.file.fileName);
              let contentDisposition = res.headers["content-disposition"];
              let filename = contentDisposition
                .split(";")[1]
                .trim()
                .split("=")[1]
                .replace(/"/g, "");
              link.setAttribute("download", decodeURIComponent(filename));
              document.body.appendChild(link);
              link.click();
              if (_this.getPackageSimpleByVal("feaDwgPdfDownload")) {
                _this.cancelDown();
              }
            }
          })
          .catch(err => {
            console.log("err", err);
          });
      }
    },
    // 获取工程类型
    getProjectType(projectType, type = "name") {
      let index = this.typeList.findIndex(item => item.id == projectType);
      if (index > -1) return this.typeList[index][type];
    },

    // 跳转到cad页面
    addCad() {
      this.loadingBtn = false;
      this.addDialog = true;
      this.dialogData = this.resetFormData();
      this.imgraw = null;
      this.isUploadFile = false;
      this.fileraw = null;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
      this.dialogData.userId = this.userInfo.userId || this.userInfo.id;
      this.getCityList();
      this.createDialog = true;
    },
    editCad(it, index) {
      this.loadingBtn = false;
      this.addDialog = false;
      this.dialogIndex = index;
      this.dialogData.id = it.id;
      this.dialogData.name = it.name;
      this.dialogData.userId = it.userId;
      this.dialogData.type = 1;
      this.dialogData.buildingHeight = it.buildingHeight || "";
      this.dialogData.buildingArea = it.buildingArea || "";
      this.dialogData.userCount = it.userCount || 0;
      this.dialogData.snapshot = it.snapshot;
      this.dialogData.file = it.file;
      this.dialogData.status = it.status;
      this.dialogData.createTime = it.createTime;
      this.dialogData.projectType = it.projectType;
      this.imgraw = null;
      this.isUploadFile = false;
      this.fileraw = null;
      this.dialogData.projectTypeId = this.getProjectType(it.projectType);
      this.dialogData.provinceCode =
        it.area && it.area.provinceCode ? it.area.provinceCode : "440000000000";
      this.dialogData.cityCode =
        it.area && it.area.cityCode ? it.area.cityCode : "";
      this.dialogData.countyCode =
        it.area && it.area.countyCode ? it.area.countyCode : "";
      if (it.file && it.file.md5) {
        this.dialogData.type = 2;
        this.isUploadFile = true;
        this.dialogData.fileraw = it.file.url;
      }
      this.getCityList();
      this.getCountryList();
      this.createDialog = true;
    },
    // 删除工程
    delCad(id) {
      this.$confirm("确定删除该记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          del(this.$setApi("/cad/project?id=" + id)).then(res => {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.page = 0;
            this.init();
          });
        })
        .catch(err => {
          // this.$message.error("系统错误，请联系管理员");
        });
    },
    goDetail(it) {
      if (it.status == 1) {
        let md5 = (it.file && it.file.md5) || "";
        let path = "cad";
        this.$router.push({
          path: path,
          query: {
            md5,
            productId: it.id,
            productName: it.name
          }
        });
      } else this.$message.warning("文件加载中，请稍后!!");
    },
    cancelProblem() {
      this.showDialog = false;
    },
    choseType(val) {
      this.typeList.some(item => {
        if (item.id == val) {
          this.$set(this.dialogData, "projectTypeId", item.name);
          this.$set(this.dialogData, "projectType", val);
          this.$set(this.dialogData, "snapshot", item.icon);
          return true;
        }
      });
      console.log("choseType", this.dialogData.snapshot);
    },

    handleMapChange(file) {
      if (file && file.raw) {
        let type = file.raw.type
          ? file.raw.type
          : file.raw.name
          ? file.raw.name
          : "";
        if (type && type.indexOf("dwg") > -1) {
          if (file.raw.size > 1024 * 1024 * 12) {
            this.$message.warning("dwg文件大于12M,不能上传");
            return;
          }
          this.fileraw = file.raw;
          this.isUploadFile = true;
          this.$set(this.dialogData.file, "fileName", file.name);
          this.$set(this.dialogData, "fileraw", file.name);
          this.$refs.form.clearValidate();
        } else this.$message.warning("请上传dwg格式文件!!!");
      } else this.$message.warning("请上传dwg格式文件!!!");
    },
    uploadFileMap(file) {
      console.log("ffffff", file);
    },
    handleImg(file) {
      this.imgraw = file.raw;
      this.$set(this.dialogData, "snapshot", URL.createObjectURL(file.raw));
    },
    doSubmit() {
      this.validateForm("form", (valid, mess) => {
        if (valid) {
          if (this.getPackageSimpleByVal("feaCreateProjectArea")) {
            if (this.dialogData.buildingArea == 0) {
              this.$message.warning("建筑面积必须大于0");
              return;
            }
            if (this.areaNum > 0) {
              if (this.dialogData.buildingArea > Number(this.areaNum) * 10000) {
                this.$message.warning(
                  "建筑面积不能大于" + this.areaNum + "万平方米"
                );
                return;
              }
            }
          }
          if (this.dialogData.buildingHeight == 0) {
            this.$message.warning("建筑高度必须大于0");
            return;
          }

          if (
            this.dialogData.userCount !== "" &&
            (this.dialogData.userCount == 0 || this.dialogData.userCount) &&
            !/^\+?[1-9]\d*$/.test(this.dialogData.userCount)
          ) {
            this.$message.warning("使用人数必须是正整数");
            this.dialogData.userCount = "";
            return;
          }

          if (
            this.cityList.length > 0 &&
            this.dialogData.cityCode.length == 0
          ) {
            this.$message.warning("请选择城市");
            return;
          }
          if (
            this.countyList.length > 0 &&
            this.dialogData.countyCode.length == 0
          ) {
            this.$message.warning("请选择区县");
            return;
          }
          let postData = new FormData();
          postData.append("id", this.dialogData.id);
          postData.append("name", this.dialogData.name);
          postData.append("projectType", this.dialogData.projectType);
          postData.append("status", this.dialogData.status);
          postData.append("userId", this.userInfo.userId || this.userInfo.id);
          this.loadingBtn = true;
          postData.append("area.provinceCode  ", this.dialogData.provinceCode);
          postData.append("area.cityCode  ", this.dialogData.cityCode || "");
          postData.append(
            "area.countyCode  ",
            this.dialogData.countyCode || ""
          );
          postData.append("status", this.dialogData.status);
          if (this.dialogData.userCount)
            postData.append("userCount", this.dialogData.userCount);
          if (this.dialogData.buildingArea)
            postData.append("buildingArea", this.dialogData.buildingArea);
          if (this.dialogData.buildingHeight)
            postData.append("buildingHeight", this.dialogData.buildingHeight);
          if (this.fileraw) postData.append("projectFile", this.fileraw);
          if (this.imgraw) postData.append("snapshotFile", this.imgraw);
          else {
            let snapshot = "";
            this.typeList.some(item => {
              if (item.id == this.dialogData.projectType) {
                if (!this.imgraw) snapshot = item.icon;
                return true;
              }
            });
            postData.append("snapshot", snapshot);
          }
          if (this.addDialog) {
            if (this.isPtUser) {
              onCarProject(postData)
                .then(res => {
                  this.$message.success("操作成功");
                  this.showDialog = false;
                  this.loadingBtn = false;
                  this.page = 0;
                  this.init();
                  this.resetData();
                })
                .catch(() => {
                  this.loadingBtn = false;
                });
            } else
              postCarProject(postData)
                .then(res => {
                  this.$message.success("操作成功");
                  this.showDialog = false;
                  this.loadingBtn = false;
                  this.page = 0;
                  this.init();
                  this.resetData();
                })
                .catch(() => {
                  this.loadingBtn = false;
                });
          } else {
            editCarProject(postData)
              .then(res => {
                this.$message.success("操作成功");
                this.showDialog = false;
                this.loadingBtn = false;
                this.init();
                this.resetData();
              })
              .catch(() => {
                this.loadingBtn = false;
              });
          }
        } else {
          this.loadingBtn = false;
          this.$message.warning(mess[0].message);
        }
      });
    },
    validateForm(formName = "form", call) {
      const promises = [this.$refs[formName]];
      let count = 0;
      for (let index = 0; index < promises.length; index++) {
        const element = promises[index];
        element.validate((result, errors) => {
          if (result) {
            count++;
            if (count == promises.length) {
              call(true);
              return;
            }
          } else {
            const fkey = Object.keys(errors)[0];
            call(false, errors[fkey]);
            return;
          }
        });
      }
    },
    cancelCreateDialog() {
      this.createDialog = false;
    },
    resetFormData() {
      this.isUploadFile = false;
      return {
        id: "",
        name: "",
        projectTypeId: "",
        projectType: "",
        buildAddress: "",
        provinceCode: "440000000000",
        cityCode: "",
        countyCode: "",
        addressId: "",
        userId: "",
        type: 1,
        buildingHeight: "",
        buildingArea: "",
        userCount: "",
        snapshot: "",
        file: {
          fileName: null,
          fileSize: null,
          id: null,
          md5: null,
          url: null
        },
        fileraw: null,
        status: "1",
        createTime: new Date()
      };
    },

    resetData() {
      this.dialogData = this.resetFormData();

      this.imgraw = null;
      this.addDialog = true;
      this.createDialog = false;
    }
  },
  watch: {}
};
</script>
<style lang="stylus" scoped>
.home-container
  overflow hidden

  .home-wrapper
    margin-top 32px
    margin-bottom 12px
    height calc(100% - 44px)

  .home-left-box
    width 240px
    flex-direction column
    background #FFFFFF
    border-radius 8px
    justify-content flex-start
    align-items center
    padding 24px
    box-sizing border-box

    .user-avatar
      position relative
      overflow hidden
      width 192px
      height 192px
      border-radius 10px

      img
        width 100%
        height 100%

      .user-type-box
        position absolute
        top 0
        left 0
        padding 4px 12px
        border-bottom-right-radius 10px
        background rgba(203, 152, 74, .8)
        color #fff
        border-top-left-radius 10px
        font-size 12px

    .user-name
      margin-top 12px
      margin-bottom 24px
      width 100%
      font-size 24px
      color #333
      text-align left
      align-items center
      justify-content space-between

      .vip-img
        margin-left 8px
        width 43px
        height 24px

    .user-info
      width 100%
      font-weight 400
      font-style normal
      font-size 14px
      text-align left
      letter-spacing normal
      color #999

      .user-date
        margin-top 8px

  .home-right-box
    position relative
    width calc(100% - 264px)
    height 100%
    margin-left 24px
    padding 24px
    box-sizing border-box
    border-radius 8px
    background #fff

    .work-top
      width 100%
      height 58px
      justify-content space-between

    .work-wrapper
      position absolute
      top 80px
      bottom 0
      left 24px
      right 24px
      overflow hidden

      .work-main
        flex-wrap wrap
        height calc(100% - 60px)
        overflow-y auto
        padding-bottom 16px

      .work-add
        flex-direction column
        justify-content center
        align-items center
        width 100%
        height 100%
        background rgba(52, 120, 247, .05)
        border-radius 4px 4px 4px 4px
        color #3478F7
        font-size 16px
        font-weight 400
        border 1px dashed #3478F7

        i
          font-size 40px
          font-weight 400

        .addNew
          margin 8px 0

      .work-item
        position relative
        overflow hidden
        margin-top 16px
        margin-right 10px
        width 254px
        height 433px
        padding 16px
        flex-direction column
        background #fefefe
        box-sizing border-box
        border-radius 8px
        box-shadow 0px 8px 20px 0px rgba(9, 43, 80, .2)
        cursor pointer

        &:hover .work-mask-box
          opacity 1

        &:nth-child(4n)
          margin-right 0

        .work-img-box
          width 100%
          height 294px
          overflow hidden

          img
            width 100%
            min-height 100%

        .work-info-box
          margin-top 12px
          width 100%
          height calc(100% - 294px)
          flex-direction column
          justify-content space-between

          .work-name
            font-size 16px
            font-weight 600
            color #3D3D3D
            line-height 22px

          .work-type
            display inline-block
            margin-top 8px
            padding 0 4px
            height 21px
            line-height 21px
            font-size 12px
            font-family PingFang SC-Regular, PingFang SC
            font-weight 400
            color #3478F7
            background rgba(52, 120, 247, .14)
            border-radius 2px

          .work-desc
            font-size 12px
            font-family PingFang SC-Regular, PingFang SC
            font-weight 400
            color #999999
            line-height 17px

      .work-mask-box
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        flex-direction column
        border-radius 8px
        background rgba(13, 19, 14, .2)
        z-index 100
        opacity 0

        .work-mask-item
          margin-bottom 16px
          width 64px
          height 64px

        .look
          background url('~images/home/look.png') center no-repeat
          background-size 100% 100%

          &:hover
            background url('~images/home/look_active.png') center no-repeat
            background-size 100% 100%

        .edit
          background url('~images/home/edit.png') center no-repeat
          background-size 100% 100%

          &:hover
            background url('~images/home/edit_active.png') center no-repeat
            background-size 100% 100%

        .down
          background url('~images/home/down.png') center no-repeat
          background-size 100% 100%

          &:hover
            background url('~images/home/down_active.png') center no-repeat
            background-size 100% 100%

        .del
          background url('~images/home/del.png') center no-repeat
          background-size 100% 100%

          &:hover
            background url('~images/home/del_active.png') center no-repeat
            background-size 100% 100%

    .work-top-left
      width 140px

      .work-top-title
        position relative
        padding-bottom 11px
        font-size 24px
        font-weight 600
        color #222

      .work-top-counte
        font-size 14px
        color #999

    .work-top-right
      >>>.el-input--small .el-input__inner, >>>.el-input--small .el-input__icon
        height 46px
        line-height 46px
        border-radius 24px

.address-box .el-cascader__label
  display none

.dialog-footer
  display flex
  justify-content flex-end

>>>.el-pagination .el-select .el-input .el-input__inner
  background $menuBg

>>>.el-pagination i
  color #fff

.dialog-edit
  position absolute
  top 25px
  left 26%
  transform translateX(-50%)
  z-index 100
  cursor pointer

  svg
    margin-right 8px

  img
    width 24px

  &:hover
    .dialog-edit-box
      display block

  .dialog-edit-box
    position absolute
    top 40px
    left -80px
    display none
    width 234px
    padding 12px 16px
    background #fff
    border-radius 4px
    box-sizing border-box
    box-shadow 0 5px 10px #ddd
    transform translateX(-50%)

    &:before
      position absolute
      top -10px
      content ''
      display block
      width 0
      height 0
      left 50%
      border 5px solid
      border-color transparent transparent #f5f8ff transparent
      transform translateX(-50%)

  .edit-title
    margin-bottom 8px
    font-size 14px
    color #222
    font-weight 500

  .edit-item
    font-size 14px
    color #666
    font-weight 400
    line-height 20px

.drag-icon
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)

>>>.el-upload-dragger
  display flex
  justify-content center
  align-items center

.file-tips
  position absolute
  top 50%
  left 89px
  font-size 12px
  color #F56C6C
  transform translateY(-50%)
</style>
<style scoped>
@import "~@/assets/css/elementUi.css";
</style>
